

























































































import Vue from "vue";
import {
  departmentList,
  departmentDelete,
  SynchronousDepartment,
} from "@/api/user/department";
import editForm from "./editForm.vue";

export default Vue.extend({
  components: { editForm },
  data() {
    return {
      searchObj: {
        keyword: "",
      },
      pageObj: {
        pageSize: 10,
        pageNum: 1,
      },
      pageSizesList: [10, 15, 20, 50, 100],
      tableTotal: 0,
      tableData: [],
      editFormVisible: false,
      isEditFlag: false,
      baseEditInfo: {},
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    //common
    resetFn() {
      this.searchObj = this.$options.data().searchObj;
      this.searchFn();
    },
    searchFn() {
      this.pageObj = this.$options.data().pageObj;
      this.getList();
    },
    pageNumChange(val: any) {
      this.pageObj.pageNum = val;
      this.getList();
    },
    pageSizeChange(val: any) {
      this.pageObj.pageNum = 1;
      this.pageObj.pageSize = val;
      this.getList();
    },
    getList() {
      this.getListData();
      this.getStaData();
    },
    listEditFn(row, type) {
      this.editFormVisible = true;
      this.isEditFlag = type == "see" ? false : true;
      this.baseEditInfo = row;
    },
    listDeleteFn(row: any) {
      this.$confirm("是否删除本条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        departmentDelete(row.id).then((res) => {
          this.$message({
            type: "success",
            message: "删除成功",
          });
          this.searchFn();
        });
      });
    },
    addFn() {
      this.editFormVisible = true;
      this.baseEditInfo = this.$options.data().baseEditInfo;
      this.isEditFlag = true;
    },
    synFn() {
      this.$message.warning("正在同步，请稍后...");
      SynchronousDepartment().then((res) => {
        if (res.status == "SUCCESS") {
          this.$message.success("同步成功");
          this.searchFn();
        } else {
          this.$message.warning("同步失败");
        }
      });
    },
    deleteFn() {
      console.log("删除");
    },
    closeFn(flag = false) {
      this.editFormVisible = false;
      if (flag) {
        this.getList();
      }
    },
    //业务

    getListData() {
      departmentList({}).then((res) => {
        this.tableData = res.content || [];
        this.tableTotal = res.content.length || 0;
      });
    },
    getStaData() {
      console.log("获取统计数据");
    },
  },
});









































































import Vue from "vue";
import {
  departmentAdd,
  departmentUpdate,
  departmentDetails,
  departmentList,
} from "@/api/user/department";
export default Vue.extend({
  props: ["isEditFlag", "baseEditInfo"],
  data() {
    return {
      editFormInfo: {
        name: null, //部门名称
        parentId: null, //上级部门
      },
      editFormInfoRules: {
        name: [{ required: true, message: "请输入部门名称" }],
      },
      defaultProps: {
        children: "children",
        label: "name",
      },
      parentList: [],
      expandedList: [],
      filterText: "",
    };
  },
  watch: {
    filterText(val) {
      this.$refs.treeForm.filter(val);
    },
  },
  mounted: async function () {
    await departmentList().then((res) => {
      this.parentList = res.content || [];
    });
    if (this.baseEditInfo.id) {
      await departmentDetails(this.baseEditInfo.id).then((res) => {
        this.editFormInfo = this.$deepCopy(res.content);
      });
    }
    this.$nextTick(() => {
      if (this.baseEditInfo.parentId) {
        this.getDefaultExtent(this.parentList, this.baseEditInfo.id);
      }
    });
  },
  methods: {
    parentIdChangeFn(val){
      console.log(val)
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    getDefaultExtent(data, id) {
      if (!data) return; // return; 中断执行
      for (let i in data) {
        let item = data[i];
        if (this.getObjByTree(item.children, id)) {
          this.expandedList.push(item.id);
          if (item.children && typeof item.children == "object") {
            this.getDefaultExtent(item.children, id);
          }
          break;
        }
      }
    },
    getObjByTree(data, id) {
      let result = null;
      if (!data) return; // return; 中断执行
      for (let i in data) {
        if (result !== null) break;
        let item = data[i];
        if (item.id === id) {
          result = item;

          this.setDisable(item);
          break;
        } else if (item.children && typeof item.children == "object") {
          result = this.getObjByTree(item.children, id);
        }
      }
      return result;
    },
    setDisable(data) {
      data.disabled = true;
      if (data.children && typeof data.children == "object") {
        data.children.forEach((item: any) => {
          this.setDisable(item);
        });
      }
    },
    treeCheck: function (node, list) {
      //node 该节点所对应的对象、list 树目前的选中状态对象
      //选中事件在选中后执行，当lis中有两个选中时，使用setCheckedKeys方法，选中一个节点
      if (list.checkedKeys.length == 2) {
        //单选实现
        this.$refs.treeForm.setCheckedKeys([node.id]);
      }
      this.editFormInfo.parentId = node.id;
    },
    saveFn: async function () {
      let flag = true;
      (this.$refs.editFormInfoForm as any).validate((valid: boolean) => {
        if (!valid) {
          flag = false;
        }
      });
      if (!flag) {
        return;
      }

      let obj = this.$deepCopy(this.editFormInfo);
      if (obj.id) {
        departmentUpdate(obj.id, obj).then((res: any) => {
          this.$message.success("保存成功");
          this.closeFn(true);
        });
      } else {
        departmentAdd(obj).then((res: any) => {
          this.$message.success("保存成功");
          this.closeFn(true);
        });
      }
    },
    closeFn(flag = false) {
      this.$emit("closeFn", flag);
    },
  },
});
